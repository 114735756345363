import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Pagination = ({
  rowsPerPage,
  rowsPerPageOptions,
  count,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const startRow = page * rowsPerPage + 1;
  const endRow =
    rowsPerPage === -1 ? count : Math.min(count, (page + 1) * rowsPerPage);

  return (
    <div className="flex justify-center items-center dark:bg-gray-800">
      <div className="p-2 border-t dark:border-gray-700 w-full">
        <div className="items-center justify-end space-x-4 flex">
          <div className="flex items-center justify-center">
            <div className="flex items-center space-x-2">
              <label>
                <span className="text-sm font-medium mr-2 dark:text-white">
                  Rows per page
                </span>
                <select
                  value={rowsPerPage}
                  onChange={onRowsPerPageChange}
                  className="h-8 text-sm pr-8 leading-none transition duration-75 border-gray-300 rounded-lg shadow-sm outline-none focus:border-[#03C9D7] focus:ring-1 focus:ring-inset focus:ring-[#03C9D7] dark:text-white dark:bg-gray-700 dark:border-gray-600 dark:focus:border-[#03C9D7"
                >
                  {rowsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                  <option value="-1">All</option>
                </select>
              </label>
            </div>
          </div>
          <div className="flex items-center">
            <div className="mx-2 text-sm font-medium dark:text-white">
              {startRow} - {endRow} of {count}
            </div>
            <div className="py-3 border rounded-lg dark:border-gray-600">
              <ol className="flex items-center text-sm text-gray-500 divide-x rtl:divide-x-reverse divide-gray-300 dark:text-gray-400 dark:divide-gray-600">
                <li>
                  <button
                    type="button"
                    disabled={page === 0}
                    onClick={() => onPageChange(page - 1)}
                    className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md outline-none hover:bg-gray-500/5 focus:bg-yellow-500/10 focus:ring-2 focus:ring-[#03C9D7] dark:hover:bg-gray-400/5 transition text-main"
                    aria-label="Previous"
                    rel="prev"
                  >
                    <BsChevronLeft />
                  </button>
                </li>

                <li>
                  <button
                    type="button"
                    disabled={endRow >= count}
                    onClick={() => onPageChange(page + 1)}
                    className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md outline-none hover:bg-gray-500/5 focus:bg-yellow-500/10 focus:ring-2 focus:ring-p#03C9D7] dark:hover:bg-gray-400/5 transition text-[#03C9D7]"
                    aria-label="Next"
                    rel="next"
                  >
                    <BsChevronRight />
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
