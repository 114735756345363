import { gql } from "@apollo/client";

export const getAllTransactions = gql`
  query MyQuery(
    $id: uuid!
    $datefilter: timestamptz_comparison_exp
    $limit: Int
    $offset: Int
  ) {
    customers_by_pk(id: $id) {
      phone
      username
      email
      id
      status
      username
      transaction_reports(
        limit: $limit
        offset: $offset
        where: { created_at: $datefilter }
        order_by: { created_at: desc }
      ) {
        id
        message_body
        message_length
        message_title
        mobile_phone
        operator_name
        reason
        status
        transaction_code
        updated_at
        fk_customer_id
        created_at
      }
      transaction_reports_aggregate(
        where: { fk_customer_id: { _eq: $id }, created_at: $datefilter }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
