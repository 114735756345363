import { gql } from "@apollo/client";

const getCustomerByAuth = gql`
  subscription GetAllCustomers($id: uuid!) {
    customers_by_pk(id: $id) {
      email
      id
      created_at
      disabled
      phone
      status
      updated_at
      username
      customer_provider {
        sms_package
        used_sms
        sms_reference_id
        mytel_sms
        ooredoo_sms
        mpt_sms
        atom_sms
      }
    }
  }
`;

export default getCustomerByAuth;
