import React from "react";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import { formatDate } from "../data/dummy";
import TextField from "../components/TextField";
import SelectBox from "../components/SelectBox";
import ReactDatePicker from "react-datepicker";
import { useQuery } from "@apollo/client";
import { getAllTransactions } from "../graphql/queries/GetAllTransactionByCustomer";
import ExcelJS from "exceljs";
import Pagination from "../components/Pagination";
import { groupBy } from "lodash";
const TransactionReports = () => {
  const { userId } = useAuth();
  const [selected, setSelected] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = React.useState(currentDate);
  const [endDate, setEndDate] = React.useState(new Date());
  const [searchPhone, setSearchPhone] = React.useState("");
  const { data } = useQuery(getAllTransactions, {
    variables: {
      id: userId,
      datefilter:
        startDate && endDate
          ? { _gte: startDate.toISOString(), _lte: endDate.toISOString() }
          : { _is_null: false },
      limit: rowsPerPage,
      offset: page * rowsPerPage,
    },
  });
  const countValue =
    data?.customers_by_pk?.transaction_reports_aggregate?.aggregate?.count;

  React.useEffect(() => {
    if (countValue !== undefined) {
      setCount(countValue);
    }
  }, [countValue]);

  const transaction_reports =
    Array.isArray(data?.customers_by_pk?.transaction_reports) &&
    data?.customers_by_pk?.transaction_reports.length > 0
      ? data?.customers_by_pk?.transaction_reports
      : [];
  const excelData = transaction_reports.map((element) => ({
    TransactionID: element.id,
    Date: formatDate(element.created_at),
    Mobile_Phone: element.mobile_phone,
    SenderID: element.message_title,
    Content: element.message_body,
    Status: element.status,
    MessageLength: element.message_length,
  }));
  const exportToExcel = async () => {
    if (!excelData.length) {
      alert("No data to export");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add headers
    const headers = Object.keys(excelData[0]);
    worksheet.addRow(headers);

    // Add data
    excelData.forEach((row) => {
      worksheet.addRow(Object.values(row));
    });

    // Apply styles
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `axpigeon_client_${formatDate(new Date())}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getTransaction = (transactionData) => {
    return Object.entries(
      groupBy(
        transactionData
          .filter((p) => (!searchPhone ? p : p.mobile_phone === searchPhone))
          .filter((u) =>
            !selected?.username || selected?.username === "ALL"
              ? u
              : u.status === selected?.username
          )
      )
    );
  };
  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-24 p-2 md:p-10 rounded-3xl bg-white dark:bg-secondary-dark-bg">
      <Header category="Reports" title="Transaction Reports" />
      <div className="overflow-x-auto w-full">
        <div className="mb-4 grid grid-cols-12 gap-4">
          <div className="lg:col-span-9 col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">Filter By Phone</h4>
                <TextField
                  inputSize="small"
                  fullWidth
                  className="placeholder:text-sm"
                  placeholder="Search By Phone"
                  onChange={(e) => setSearchPhone(e.target.value)}
                />
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">Filter By Status</h4>
                <SelectBox
                  name="Filter By Status"
                  data={[
                    { id: 1, username: "ALL" },
                    { id: 2, username: "SUCCESS" },
                    { id: 5, username: "FAILED" },
                  ]}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">
                  Filter By From Date
                </h4>
                <label
                  htmlFor="fromDate"
                  className="border cursor-pointer block px-3 py-[9px] rounded-lg"
                >
                  <ReactDatePicker
                    id="fromDate"
                    className="bg-transparent w-full outline-none dark:text-white border-none text-gray-400 cursor-pointer placeholder:text-sm"
                    placeholderText="Filter By From Date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </label>
              </div>
              <div className="lg:col-span-4 md:col-span-3 sm:col-span-6 col-span-12">
                <h4 className="text-black dark:text-white">
                  Filter By To Date
                </h4>
                <label
                  htmlFor="toDate"
                  className="border cursor-pointer block px-3 py-[9px] rounded-lg"
                >
                  <ReactDatePicker
                    id="toDate"
                    className="bg-transparent w-full outline-none dark:text-white border-none text-gray-400 cursor-pointer placeholder:text-sm"
                    placeholderText="Filter By To Date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 col-span-12">
            <button
              onClick={exportToExcel}
              className="bg-green-600 mt-5 w-full block px-4 py-2 rounded text-white border-none"
            >
              Excel Export
            </button>
          </div>
        </div>
        <table className="w-full">
          <thead>
            <tr>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                No.
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                TransactionID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Date/Time
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MobileNo
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                SenderID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Operator Name
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MessageBody
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Status
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MessageLength
              </th>
            </tr>
          </thead>
          <tbody>
            {getTransaction(transaction_reports).length ? (
              getTransaction(transaction_reports).map((d, index) =>
                d[1].map((transaction, index) => (
                  <tr key={transaction.id}>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {index + 1}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.id}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {formatDate(transaction.created_at)}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.mobile_phone}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.message_title}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.operator_name}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.message_body}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.status}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border p-2">
                      {transaction.message_length}
                    </td>
                  </tr>
                ))
              )
            ) : (
              <tr>
                <td colSpan={9} className="text-center dark:text-white">
                  No transactions found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 100]}
        count={count}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TransactionReports;
